/**
 * Combine all reducers in this file and export the combined reducers.
 */

import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import history from '../utils/history';

import Snackbar from "./snackbarReducer";
import Loader from "./loaderReducer";

/**
 * Merges the main reducer with the router state and dynamically injected reducers
 */
export default function createReducer() {
  const rootReducer = combineReducers({
    history: connectRouter(history),
    Snackbar,
    Loader
  });

  return rootReducer;
}
