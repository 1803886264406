
import { lazy } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

const ErrorPage = lazy(() => import("customComponents/errorPage"));

const Layout = lazy(() => import("containers/layout"));
const LandingPage = lazy(() => import("containers/landingPage"));

const RoutesConfig = () => (
  <Router>
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route path="/" element={<LandingPage />} />
        {/* <Route path="*" element={<ErrorPage />} /> */}
      </Route>
      <Route path="*" element={<ErrorPage />} />
    </Routes>
  </Router>
);
export default RoutesConfig;
