
const initState = {
    open: false
}

const SnackbarReducer = (state = initState, action) => {
    switch (action.type) {
        case 'OPEN_SNACKBAR': {
            return {
                ...state,
                open: true,
                message: action.message,
                type: action.msgType,
            };
        }
        case 'CLOSE_SNACKBAR': {
            return {
                ...state,
                open: false,
                message: null,
                type: null,
            };
        }
        default: {
            return {
                ...state,
            };
        }
    }
};

export default SnackbarReducer;
