const LoaderReducer = (state = {}, action) => {
  if (action.loader === true || action.loader === false) {
    return {
      ...state,
      loader: action?.loader,
      loaderText: action?.loaderText
    };
  }
  return {
    ...state,
  };
};

export default LoaderReducer;
