import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Snackbar from '../customComponents/snackbar';
import * as SnackbarAction from '../actions/snackbarAction';

function mapStateToProps(state) {
  return {
    ...state,
    open: state.Snackbar.open,
    message: state.Snackbar.message,
    type: state.Snackbar.type,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      ...SnackbarAction,
    },
    dispatch,
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(Snackbar);
