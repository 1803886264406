import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Loader from '../customComponents/loader';
import * as Action from '../actions/loaderAction';

function mapStateToProps(state) {
  return {
    // loader: state?.Loader?.loader,
    // loaderText: state?.Loader?.loaderText
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      // ...Action,
    },
    dispatch,
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(Loader);
