import { all, fork } from 'redux-saga/effects';

// import { landgingPageWatcherSaga } from './sagaLandingpage';

export default function* rootSaga() {
  yield all([
    // add other watchers to the array
    // fork(landgingPageWatcherSaga),
  ]);
}
