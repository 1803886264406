
const Loader = (props) => {
    const { loader = true, loaderText = "Loader Text" } = props;

    if(loader) {
        return (
            <div>
                {loaderText}
            </div>
        );
    }
    return null;
};

export default Loader;
