import { useEffect } from "react";
import "./index.css"

const Snackbar = (props) => {
    const {open, message="Success!!", autoHideDuration = 3000, position, type="", showIcon, closeSnackBar = () => {} } = props;

    useEffect(() => {
        setTimeout(() => {
            closeSnackBar();
        }, autoHideDuration);
    }, [open])

    const close = () => {
        closeSnackBar();
    };

    if(open) {
        return (
            <div className="snackbar-container">
                <div className={`snackbar-container-content ${type}`}>
                    {showIcon &&
                        <div>Icon</div>
                    }
                    {message}
                    {/* <div onClick={close}>
                        X
                    </div> */}
                </div>
            </div>
        );
    }
    return null;
}

export default Snackbar;
