// Snackbar Action
export const openSnackbar = (message, msgType) => {
    return {
        type: 'OPEN_SNACKBAR',
        message,
        msgType,
    }
};

export const closeSnackBar = () => ({
    type: 'CLOSE_SNACKBAR',
});
