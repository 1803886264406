
import { Suspense } from 'react';

import { Provider } from 'react-redux';

import store from "stores";
import Routes from 'routes';

import Snackbar from "../snackbarContainer";
import Loader from "../loaderContainer";

function App() {
  return (
    <Provider store={store}>
      <Suspense fallback={<Loader />}>
        <Snackbar />
        {/* <Loader /> */}
        <Routes />
      </Suspense>
    </Provider>
  );
}

export default App;
